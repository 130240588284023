var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-wrapper',{attrs:{"validator":_vm.$v.createForm}},[_c('b-card',{attrs:{"title":_vm.$t('project.addBook')}},[_c('b-row',[_c('form-group',{attrs:{"name":"title","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.bookName')},model:{value:(_vm.createForm.title),callback:function ($$v) {_vm.$set(_vm.createForm, "title", $$v)},expression:"createForm.title"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"authors","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('add-author',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.authors')},model:{value:(_vm.createForm.authors),callback:function ($$v) {_vm.$set(_vm.createForm, "authors", $$v)},expression:"createForm.authors"}},'add-author',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"categories","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('add-category',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.categories')},model:{value:(_vm.createForm.categories),callback:function ($$v) {_vm.$set(_vm.createForm, "categories", $$v)},expression:"createForm.categories"}},'add-category',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"publisher","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.publisher')},model:{value:(_vm.createForm.publisher),callback:function ($$v) {_vm.$set(_vm.createForm, "publisher", $$v)},expression:"createForm.publisher"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"year","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.publishDate')},model:{value:(_vm.createForm.year),callback:function ($$v) {_vm.$set(_vm.createForm, "year", $$v)},expression:"createForm.year"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"shelf","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.shelf')},model:{value:(_vm.createForm.shelf),callback:function ($$v) {_vm.$set(_vm.createForm, "shelf", $$v)},expression:"createForm.shelf"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"barcode","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"type":"number","min":"100000","max":"999999","placeholder":_vm.$t('project.barcode')},model:{value:(_vm.createForm.barcode),callback:function ($$v) {_vm.$set(_vm.createForm, "barcode", $$v)},expression:"createForm.barcode"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"isLoanable","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-form-checkbox',_vm._g(_vm._b({attrs:{"switch":""},model:{value:(_vm.createForm.isLoanable),callback:function ($$v) {_vm.$set(_vm.createForm, "isLoanable", $$v)},expression:"createForm.isLoanable"}},'b-form-checkbox',attrs,false),listeners),[_vm._v(" Ödünç Alınabilir ")])}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"description","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.description')},model:{value:(_vm.createForm.description),callback:function ($$v) {_vm.$set(_vm.createForm, "description", $$v)},expression:"createForm.description"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addBook}},[_vm._v(_vm._s(_vm.$t('project.add')))]),_c('b-button',{staticClass:"ml-2",on:{"click":_vm.reset}},[_vm._v("Sıfırla")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }