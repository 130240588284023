<template>
  <form-wrapper :validator="$v.createForm">
    <b-card :title="$t('project.addBook')">
      <b-row>
        <form-group name="title" xs="12" no-label>
          <b-input
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            :placeholder="$t('project.bookName')"
            v-model="createForm.title"
          />
        </form-group>
      </b-row>
      <b-row>
        <form-group name="authors" xs="12" no-label>
          <add-author
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            v-model="createForm.authors"
            :placeholder="$t('project.authors')"
          />
        </form-group>
      </b-row>
      <b-row>
        <form-group name="categories" xs="12" no-label>
          <add-category
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            v-model="createForm.categories"
            :placeholder="$t('project.categories')"
          />
        </form-group>
      </b-row>

      <b-row>
        <form-group name="publisher" xs="12" no-label>
          <b-input
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            :placeholder="$t('project.publisher')"
            v-model="createForm.publisher"
          />
        </form-group>
      </b-row>
      <b-row>
        <form-group name="year" xs="12" no-label>
          <b-input
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            :placeholder="$t('project.publishDate')"
            v-model="createForm.year"
          />
        </form-group>
      </b-row>
      <b-row>
        <form-group name="shelf" xs="12" no-label>
          <b-input
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            :placeholder="$t('project.shelf')"
            v-model="createForm.shelf"
          />
        </form-group>
      </b-row>
      <b-row>
        <form-group name="barcode" xs="12" no-label>
          <b-input
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            type="number"
            min="100000"
            max="999999"
            :placeholder="$t('project.barcode')"
            v-model="createForm.barcode"
          />
        </form-group>
      </b-row>
      <b-row>
        <form-group name="isLoanable" xs="12" no-label>
          <b-form-checkbox
              slot-scope="{ attrs, listeners }"
              v-bind="attrs"
              v-on="listeners"
              switch
              v-model="createForm.isLoanable"
          >
            Ödünç Alınabilir
          </b-form-checkbox>
        </form-group>
      </b-row>
      <b-row>
        <form-group name="description" xs="12" no-label>
          <b-input
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            :placeholder="$t('project.description')"
            v-model="createForm.description"
          />
        </form-group>
      </b-row>
      <b-row>
        <b-col>
          <b-button @click="addBook" variant="primary">{{ $t('project.add') }}</b-button>
          <b-button @click="reset" class="ml-2">Sıfırla</b-button>
        </b-col>
      </b-row>
    </b-card>
  </form-wrapper>
</template>

<script>
  import {integer, required, maxLength, minLength} from "vuelidate/lib/validators";
  import AddAuthor from "@/view/pages/admin/book/AddAuthor";
  import AddCategory from "@/view/pages/admin/book/AddCategory";

export default {
  components: {
    AddAuthor,
    AddCategory
  },
  validations: {
    createForm: {
      title: { required },
      publisher: {},
      year: { integer },
      shelf: {},
      description: {},
      barcode: {
        required,
        maxLength: maxLength(6),
        minLength: minLength(6)
      },
      authors: {
        // required: (val) => {
        //   return val.length > 0
        // }
      },
      categories: {
        // required: (val) => {
        //   return val.length > 0
        // }
      }
    }
  },
  created() {
    this.getNewBarcode()
  },
  data() {
    return {
      createForm: {
        title: '',
        publisher: '',
        year: '',
        shelf: '',
        description: '',
        barcode: '',
        isLoanable: true,
        authors: [],
        categories: []
      },
    };
  },
  methods: {
    async addBook() {
      try {
        this.$v.createForm.$touch();
        if (this.$v.createForm.$anyError) {
          this.toast({ type: 'danger', message: 'validationError' });
          return false;
        }
        const { data } = await this.axios.post("lib/books", this.createForm);
        this.getNewBarcode()
        this.createForm.authors = data.authors;
        this.createForm.categories = data.categories;

        this.toast({ message: 'createSuccess', item: 'book' });
        this.$emit('refreshList')

      } catch (e) {
        this.toast({ type: "danger", message: "createError", item: "book" });
        console.log(e);
      }
    },
    async getNewBarcode() {
      try {

        const { data } = await this.axios.get("lib/books/newBarcode");
        this.createForm.barcode = data

      } catch (e) {
        console.log(e);
      }
    },
    reset(){
      this.createForm = {
        title: '',
        publisher: '',
        year: '',
        shelf: '',
        description: '',
        barcode: '',
        isLoanable: true,
        authors: [],
        categories: []
      };
      this.$v.createForm.$reset();
    }
  }
};
</script>

