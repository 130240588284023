var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline"}},[_c('b-button',{staticClass:"btn-icon mr-2",attrs:{"size":"xs","variant":"light-primary"},on:{"click":function($event){_vm.modal = true}}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Design/Edit.svg"}})],1)]),_c('b-modal',{attrs:{"title":_vm.$t('project.edit'),"size":"lg","no-close-on-esc":"","no-close-on-backdrop":""},on:{"close":_vm.onClose},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(!_vm.loading)?_c('form-wrapper',{staticClass:"av-tooltip tooltip-right-top",attrs:{"validator":_vm.$v.form}},[_c('b-row',[_c('form-group',{attrs:{"name":"title","md":"6","label":_vm.$t('project.bookName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}},'b-input',attrs,false),listeners))}}],null,false,232043133)}),_c('form-group',{attrs:{"name":"publisher","md":"6","label":_vm.$t('project.publisher')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.publisher),callback:function ($$v) {_vm.$set(_vm.form, "publisher", $$v)},expression:"form.publisher"}},'b-input',attrs,false),listeners))}}],null,false,1345218483)})],1),_c('b-row',[_c('form-group',{attrs:{"name":"authors","md":"6","label":_vm.$t('project.authors')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('add-author',_vm._g(_vm._b({model:{value:(_vm.form.authors),callback:function ($$v) {_vm.$set(_vm.form, "authors", $$v)},expression:"form.authors"}},'add-author',attrs,false),listeners))}}],null,false,4044808507)}),_c('form-group',{attrs:{"name":"categories","md":"6","label":_vm.$t('project.categories')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('add-category',_vm._g(_vm._b({model:{value:(_vm.form.categories),callback:function ($$v) {_vm.$set(_vm.form, "categories", $$v)},expression:"form.categories"}},'add-category',attrs,false),listeners))}}],null,false,3771063851)})],1),_c('b-row',[_c('form-group',{attrs:{"name":"year","md":"6","label":_vm.$t('project.publishDate')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}},'b-input',attrs,false),listeners))}}],null,false,1607302162)}),_c('form-group',{attrs:{"name":"shelf","md":"6","label":_vm.$t('project.shelf')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.shelf),callback:function ($$v) {_vm.$set(_vm.form, "shelf", $$v)},expression:"form.shelf"}},'b-input',attrs,false),listeners))}}],null,false,185182345)})],1),_c('b-row',[_c('form-group',{attrs:{"name":"barcode","md":"6","label":_vm.$t('project.barcode')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.barcode),callback:function ($$v) {_vm.$set(_vm.form, "barcode", $$v)},expression:"form.barcode"}},'b-input',attrs,false),listeners))}}],null,false,1430646977)}),_c('form-group',{attrs:{"name":"isLoanable","md":"6","label":"Ödünç Alınabilir"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-checkbox',_vm._g(_vm._b({attrs:{"switch":"","size":"lg"},model:{value:(_vm.form.isLoanable),callback:function ($$v) {_vm.$set(_vm.form, "isLoanable", $$v)},expression:"form.isLoanable"}},'b-checkbox',attrs,false),listeners))}}],null,false,1148272033)})],1),_c('b-row',[_c('form-group',{attrs:{"name":"description","xs":"12","label":_vm.$t('project.description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}},'b-input',attrs,false),listeners))}}],null,false,589750171)})],1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('template',{slot:"modal-footer"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("common.cancel"))+" ")]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":_vm.$v.$anyError || _vm.saved || _vm.submitting},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t("common.save"))+" "),(_vm.submitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }